@import url('https://fonts.googleapis.com/css2?family=Fraunces:opsz,wght@9..144,100;9..144,200;9..144,300;9..144,400;9..144,500;9..144,700&family=Montserrat:wght@100;200;300;400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

h1 {
    font-family: 'Fraunces', serif;
    font-weight: 500;
    font-size: 6rem;
    line-height: 1.2;
    margin: 0;
    padding: 0;
    color: #000000B3;
}

h3 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.2;
    color: #0000007D;
}

.card {
    @apply bg-card rounded-xl w-8/12 flex drop-shadow-lg;
}

.imageSection {
    @apply w-5/12;
}

.profilePic {
    @apply rounded-l-xl;
}

.textSection {
    @apply w-7/12 p-6 flex flex-col justify-center items-center;
}

.socialIcons {
    @apply mt-8 w-4/12 flex justify-between items-center text-lg z-50;
}

@media (max-width:1550px)  {
    h1 {
        font-size: 5rem;
    }
    
    h3 {
        font-size: 1rem;
    }
}

@media (max-width:1400px)  {
    h1 {
        font-size: 4rem;
    }
    
    h3 {
        font-size: 0.8rem;
    }
}

@media (max-width:1200px)  {
    .card {
        @apply flex-col justify-center items-center w-6/12;
    }
    .imageSection {
        @apply w-full;
    }

    .profilePic {
        @apply rounded-t-xl rounded-b-none;
    }
    
    .textSection {
        @apply w-full p-6 flex flex-col justify-center items-center;
    }

    h1 {
        font-size: 4rem;
    }

    h3 {
        font-size: 0.8rem;
    }
}

@media (max-width:1000px)  {
    .card {
        @apply w-7/12;
    }

    h1 {
        font-size: 4rem;
    }

    h3 {
        font-size: 0.8rem;
    }

    .socialIcons {
        @apply w-6/12;
    }
}

@media (max-width:800px)  {

    h1 {
        font-size: 3rem;
    }

    h3 {
        font-size: 0.6rem;
    }
}

@media (max-width:650px)  {
    .card {
        @apply w-10/12;
    }

    h1 {
        font-size: 4rem;
    }

    h3 {
        font-size: 0.8rem;
    }

    .socialIcons {
        @apply w-8/12;
    }
}

@media (max-width:550px)  {
    .card {
        @apply w-11/12;
    }

    h1 {
        font-size: 3rem;
    }

    h3 {
        font-size: 0.6rem;
    }
}

@media (max-width:400px)  {

    h1 {
        font-size: 2.5rem;
    }

    h3 {
        font-size: 0.6rem;
    }
}